<template>
	<div class="wrap">
		<el-page-header @back="$router.go(-1)" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
		<div class="box">
			<div class="form_box">
				<el-form ref="form" :model="form" label-width="80px">
					<el-form-item label="账户类型" prop="type" :rules="rules.required">
						<el-select v-model="form.type" placeholder="请选择">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="银行名称" prop="subbranch" v-if="form.type==3" :rules="rules.required">
						<el-input v-model="form.subbranch" placeholder="如:中国银行"></el-input>
					</el-form-item>
					<el-form-item label="开户行" prop="opening_bank" v-if="form.type==3" :rules="rules.required">
						<el-input v-model="form.opening_bank" placeholder="如:XX支行"></el-input>
					</el-form-item>
					<el-form-item label="帐号" prop="account_number" :rules="rules.required">
						<el-input v-model="form.account_number" placeholder="请输入账户号码"></el-input>
					</el-form-item>
					<el-form-item label="账户姓名" prop="account_name" :rules="rules.required">
						<el-input v-model="form.account_name" placeholder="请输入账户姓名"></el-input>
					</el-form-item>
					<el-form-item label="手机号码" prop="phone" :rules="rules.required">
						<el-input v-model="form.phone" placeholder="请输入手机号码"></el-input>
					</el-form-item>
					<el-form-item label="是否默认">
						<el-switch v-model="form.is_default" :active-value="1" :inactive-value="0">
						</el-switch>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">保存</el-button>
						<el-button @click="$router.go(-1)">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>


<script>
	// 引入公用表单验证
	import rules from "../../../common/common.rules";
	export default {
		data() {
			return {
				rules, // 表单校验
				title: this.$route.params.id ? "编辑账户" : "新增账户",
				options: [{
					value: 1,
					label: '微信'
				}, {
					value: 2,
					label: '支付宝'
				}, {
					value: 3,
					label: '银行卡'
				}],
				form: {
					id: this.$route.params.id,
					type: 1,
					account_number: '', // 帐号
					account_name: '', // 账户姓名
					subbranch: '', //银行信息
					opening_bank: '', // 支行信息
					phone: '', // 手机号码
					is_default: 0, // 是否默认
				},
				powerData: []
			};
		},
		mounted() {
			// 如果有id，查询当前角色的详情
			if (this.form.id) {
				this.$api.store.getAccount({
					id: this.form.id
				}).then(res => {
					this.form = res.data
				})
			}
		},
		methods: {
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						const loading = this.$loading();
						if (this.form.type != 3) {
							// 如果类型不为银行，则清空银行名称
							this.form.subbranch = '';
						}
						this.$api.store.saveAccount(this.form).then(res => {
							loading.close();
							if (res.code == 200) {
								// 保存成功
								this.$message.success(`保存成功`);
								this.$router.back();
							} else {
								this.$message.error(res.msg);
							}
						}).catch(err => {
							loading.close();
							this.$alert(err);
						});
					} else {
						this.$message.warning(`请检查输入错误`);
						return false;
					}
				});
			}
		}
	};
</script>
<style scoped>
	.form_box {
		max-width: 600px;
		padding-top: 30px;
	}

	.box {
		flex-grow: 1;
		overflow: auto;
	}
</style>
